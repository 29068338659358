<template>
  <div>
    <Header></Header>
    <div class="charts_box">
        <div class="containeir">
            <div class="ban_box">
                <div class="item_box">
                    <ul class="list_box" >
                        <li v-for="(item,index) in newList" :key="item.id">
                            <div class="data_box">
                                <div class="col-1 num">
                                   {{index + 1}}
                                </div>
                                <div class="col-4 text_ellipsis" :title="item.name">
                                    {{item.name}}
                                </div>
                                <div class="col-4">
                                    {{item.classify_name}}
                                </div>
                                <div class="col-3">
                                    <a :href="item.web_url" target="_blank">
                                        <img class="game_btn" src="/charts/img/btn.png">
                                    </a>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <!-- 背景 -->
                    <img class="item_bg" src="/charts/img/xftj.png">
                </div>
                <div class="item_box">
                    <ul class="list_box">
                        <li v-for="(item,index) in comprehensive" :key="item.id">
                            <div class="data_box">
                                <div class="col-1 num">
                                   {{index + 1}}
                                </div>
                                <div class="col-4 text_ellipsis" :title="item.name">
                                    {{item.name}}
                                </div>
                                <div class="col-4">
                                    {{item.classify_name}}
                                </div>
                                <div class="col-3">
                                    <a :href="item.web_url" target="_blank">
                                        <img class="game_btn" src="/charts/img/btn.png">
                                    </a>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <!-- 背景 -->
                    <img class="item_bg" src="/charts/img/zhph.png">
                </div>
            </div>
        </div>
        <!-- 背景 -->
        <img class="bg_img" src="/charts/img/bg.png" width="100%">
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '../components/Header.vue';
import Footer from '../components/Footer.vue';
import {getChartsData} from '@/api/http.js'
export default {
    name:'Charts',
    components:{
        Header,
        Footer
    },
    data() {
        return {
            comprehensive:[],//综合排行
            newList:[],//新服推荐
        }
    },
    methods:{
        getChartsData(){
            getChartsData().then((res)=>{
                if(res.code == 200){
                    this.comprehensive = res.data.comprehensive
                    this.newList = res.data.new
                }
            }).catch((err)=>{
                console.log('捕获异常', err)
                this.$message({
                    message: err.msg,
                    type: 'warning',
                    duration: 1500
                })
            })
        }
    },
    created(){
        this.getChartsData()
    }
}
</script>

<style scoped>
    .charts_box{
        position: relative;
        overflow: hidden;
        top: 0;
        left: 0;
        padding: 418px 0 50px;
        margin-top: 110px;
        background: rgb(32, 13, 13);
    }
    .bg_img{
        position: absolute;
        top: 0;
        left: 0;
    }
    .ban_box{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .item_box{
        position: relative;

    }
    .item_bg{
        position: relative;
        z-index: 1;
        width: 414px;
    }
    .list_box{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 2;
        padding: 12% 20px 0 20px;
    }
    .list_box li{
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #F2F0A6;
        list-style: none;
        padding: 10px 0;
    }
    .data_box{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .game_btn{
        transition: all 0.5s;
        width: 79px;
    }
    .game_btn:hover{
        filter: brightness(1.2);
        transition: all 0.5s;
    }
    ul li .num{
        font-size: 18px;
        text-shadow: 4px 4px 6px rgba(0, 0, 0, 0.8);
        width: 24px;
        height: 24px;
    }
    ul li:nth-child(1) .num{
        font-size: 24px;
        background: linear-gradient(to bottom, #fffb8a, #ff7f50);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: fantasy;
        font-style: italic;
        text-shadow: 4px 4px 6px rgba(0, 0, 0, 0.8);
        display: flex;
        align-items: center;
        filter: brightness(1.3);
    }
    ul li:nth-child(2) .num{
        font-size: 24px;
        background: linear-gradient(to bottom, #fffee6, #b1bbc7);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: fantasy;
        font-style: italic;
        text-shadow: 4px 4px 6px rgba(0, 0, 0, 0.8);
        display: flex;
        align-items: center;
        filter: brightness(1.3);
    }
    ul li:nth-child(3) .num{
        font-size: 24px;
        background: linear-gradient(to bottom, #fff9df, #d8ac6d);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: fantasy;
        font-style: italic;
        text-shadow: 4px 4px 6px rgba(0, 0, 0, 0.8);
        display: flex;
        align-items: center;
        filter: brightness(1.3);
    }
    @media (max-width:1300px) {
        .charts_box{
            margin-top: 100px;
        }
    }
</style>