<template>
    <div>
        <div class="new_top">
            <div class="ac_one">
                <div class="flex_box">
                    <img class="tip_img" src="/activity/spring/img/left.png">
                    <span class="ac_title">活动期间享受充值超高倍率</span>
                    <img class="tip_img" src="/activity/spring/img/right.png">
                </div>
                <span class="ac_text">充值100 充值倍率32倍</span>
                <span class="ac_text">充值200 充值倍率34倍</span>
                <span class="ac_text">充值300 充值倍率36倍</span>
                <span class="ac_text">充值500 充值倍率38倍</span>
                <span class="ac_text">充值1000 充值倍率40倍</span>
                <div class="beizhu">
                    <div class="ac_bz">备注:充值≥1000,充值倍率40倍</div>
                    <!-- 背景 -->
                    <img class="time_bg" src="/activity/spring/img/time.png">
                </div>
                <img class="djcz_btn btn_hover" src="/activity/spring/img/djcz.png" @click="toReach">
            </div>
            <!-- 背景 -->
          <img class="bg" src="/activity/spring/img/bg.png">
        </div>
    </div>
</template>

<script>
import { auto } from "@popperjs/core";
export default {
    name: 'Home',
    data() {
      return {
        isClick: true, //防抖
        isactive:false, //活动是否开启
        money:'',
        bag_num:0,
      }
    },
    methods: {
        toReach(){
            this.$router.push({
                path:'/member/wallet'
            })
        },
    },
    created(){
    }
}
</script>

<style scoped>
.beizhu{
    position: relative;
    margin-top: 17px;
}
.time_bg{
    width: 631px;
}
    .tip_img{
        width: 94px;
    }
    .new_top{
        padding-top: 395px;
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        left: 0;
        top: 0;
    }
    .bg {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -99;
        width: 100%;
    }
    .activity_box{
        position: relative;
    }
    .ac_one{
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        left: 10%;
    }
    .ac_title{
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: 28px;
        color: #A11717;
        line-height: 29px;
        background: linear-gradient(0deg, #54810D 0%, #3F6109 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin: 0 10px;
    }
    .ac_text{
        font-family: Source Han Sans SC;
        font-weight: bold;
        font-size: 22px;
        color: #43670A;
        margin-top: 10px;
    }
    .ac_bz{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        font-family: Source Han Sans SC;
        font-weight: bolder;
        font-size: 24px;
        color: #FBFFB3;
        text-stroke: 1px #224110;
        -webkit-text-stroke: 1px #224110;
        width: 631px;
        text-align: center;
    }
    .djcz_btn{
        margin-top: 30px;
        width: 352px;
    }
    .ac_title2{
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: 28px;
        color: #FFFFFF;
        line-height: 29px;
        background: linear-gradient(0deg, #86081D 0%, #CB191E 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .bag_img{
        margin-top: 15px;
    }
    .ac_num{
        font-family: Source Han Sans SC;
        font-weight: bold;
        font-size: 24px;
        color: #A81212;
    }

</style>