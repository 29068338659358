<template>
    <div class="qd">
        <div class="qd_box">
            <div class="qd_top">
                <div class="qd_title">
                    <h2>签到奖励</h2>
                    <span>累计签到获得更多奖励</span>
                </div>
                <a class="qd_btn btn_color text_ellipsis" @click="SignIn">立即签到</a>
            </div>
            <div class="qd_content">
                <div class="card_box" v-for="(item,index,i) in sigin_date" :key="i">
                    <span class="card_jl lang_point" :title="'赠送'+ (lvfl[userInfo.user_growth] ? lvfl[userInfo.user_growth].sigin_integral : '') + '积分'">赠送{{lvfl[userInfo.user_growth] ? lvfl[userInfo.user_growth].sigin_integral :''}}积分</span>
                    <span class="card_lq">{{ item ? '已领取':'未领取'}}</span>
                    <!-- 背景 -->
                    <img :class="'card_img img-responsive card_'+(i+1) " v-lazy="item ?( material.sign ? (img_url + material.sign.caise) : '')  :(material.sign ? (img_url + material.sign.heise) : '') " width="100%">
                </div>
                
            </div>
            <!-- 点 -->
            <div class="jd_box">
                <!-- <div class="jindu" :style="{width:width}"></div> -->
                <div class="xian"></div>
                <div class="dian_box">
                    <div class="dian flex_box dian_pop1">
                        <div class="dian_x dian1"></div>
                    </div>
                    <div class="dian flex_box dian_pop2">
                        <div class="dian_x dian2"></div>
                    </div>
                    <div class="dian flex_box dian_pop3">
                        <div class="dian_x dian3"></div>
                    </div>
                    <div class="dian flex_box dian_pop4">
                        <div class="dian_x dian4"></div>
                    </div>
                    <div class="dian flex_box dian_pop5">
                        <div class="dian_x dian5"></div>
                    </div>
                    <div class="dian flex_box dian_pop6">
                        <div class="dian_x dian6"></div>
                    </div>
                    <div class="dian flex_box dian_pop7">
                        <div class="dian_x dian7"></div>
                    </div>
                </div>
            </div>
            <div class="day">
                <span class="day_pop1">第一天</span>
                <span class="day_pop2">第二天</span>
                <span class="day_pop3">第三天</span>
                <span class="day_pop4">第四天</span>
                <span class="day_pop5">第五天</span>
                <span class="day_pop6">第六天</span>
                <span class="day_pop7">第七天</span>
            </div>
        </div>
        <div class="qd_bottom">
            <span>会员须知</span>
            <span v-html="newDate.content">
            </span>
        </div>
        <!-- 询问二维码弹窗 -->
        <div class="ewm_tc" ref="ewm_tc">
            <img :src="code_img_url" width="100%">
            <span>{{ ask_msg }}</span>
        </div>
        
    </div>
</template>

<script>
    import { auto } from "@popperjs/core";
    import layer from "layui-layer";
    import {getUserSigin,toSigin,getArticleData,existence,askQrcode,checkWxqrBinding,getUserInformation,getUserMerge} from '@/api/http.js'
    import { mapState } from "vuex";
    import { MessageBox } from 'element-ui';
    export default {
        name:'PersonalCenter',
        data() {
            return {
                reward:'',
                sigin_date:[],
                flag:true ,//签到按钮点击状态
                is_sigin:'',//是否可以签到
                newDate:{},
                intervalId: null,
                messageBoxInstance:null,
                code_img_url:'',
                title:'',
                ask_msg:'',
                scene_str:'',
                eoMerge:false, //81是否合并
                toMerge:false, //37是否合并
                hb_flag:true,//账号合并点击状态
                accountValue:'',
                passwordValue:''
            }
        },
        computed:{
            ...mapState([
                'getClient',
                'lvfl',
                'userInfo',
                'material'
            ]),
            sigin_eg:{
                get(){
                    return this.$store.state.sigin_eg
                },
                set(val){
                    return this.$store.state.sigin_eg = val
                }
            }
        },
        methods:{
            // 会员须知文章接口
            getArticleData(){
                const config =  JSON.parse(localStorage.getItem('config'));
                getArticleData({
                    id:config.customer.member_info
                }).then((res)=>{
                    if(res.code === 200){
                        this.newDate = res.data
                    }
                }).catch((err)=>{
                    console.log('捕获异常',err)
                    this.$message({
                        message:'暂无信息',
                        type: 'warning',
                        duration: 3000
                    })
                })
            },
            SignIn(){
                if(this.flag && this.is_sigin == true){
                    this.flag = false
                    this.toSigin() 
                }else{
                    this.flag = false
                    if(this.is_sigin == false){
                        this.$message({
                            message:'今日已经签到！',
                            type: 'warning',
                            duration: 1500,
                            onClose:()=>{
                                this.flag = true
                            }
                        })
                    }else{
                        this.$message({
                            message:'请勿重复提交，请稍后再试~',
                            type: 'warning',
                            duration: 1500,
                            onClose:()=>{
                                this.flag = true
                            }
                        })
                    }
                    
                }
            },
            //签到状态查询接口
            getUserSigin(){
                getUserSigin().then((res) =>{
                    if(res.code === 200){
                        console.log(res)
                        this.is_sigin = res.data.is_sigin
                        const a = res.data.sigin_date
                        this.sigin_date = res.data.sigin_date
                        if(a.day_1){
                            $('.dian_pop1').css("border","1px solid #A9805C")
                            $('.dian1').css("background","#A9805C")
                        }
                        if(a.day_2){
                            $('.dian_pop2').css("border","1px solid #A9805C")
                            $('.dian2').css("background","#A9805C")
                        }
                        if(a.day_3){
                            $('.dian_pop3').css("border","1px solid #A9805C")
                            $('.dian3').css("background","#A9805C")
                        }
                       if(a.day_4){
                            $('.dian_pop4').css("border","1px solid #A9805C")
                            $('.dian4').css("background","#A9805C")
                        }
                        if(a.day_5){
                            $('.dian_pop5').css("border","1px solid #A9805C")
                            $('.dian5').css("background","#A9805C")
                        }
                        if(a.day_6){
                            $('.dian_pop6').css("border","1px solid #A9805C")
                            $('.dian6').css("background","#A9805C")
                        }
                        if(a.day_7){
                            $('.dian_pop7').css("border","1px solid #A9805C")
                            $('.dian7').css("background","#A9805C")
                        }
                    }
                }).catch((err) =>{
                    console.log("捕获异常",err)
                })
            },
            // 获取签到奖励MSG接口
            toSigin(){
                toSigin().then((res) =>{
                    if(res.code === 200){
                        this.sigin_eg = res.msg
                        console.log(res);
                        this.tcSigin()
                    }
                }).catch((err) =>{
                    if(err.code === 60001){
                        // 获取倒计时元素
                        var countdownElem = document.getElementById('countdown');
                        countdownElem.innerHTML = 5
                        var that = this
                        var timer
                        var singin_tc = $('.singin_tc')
                        var LayIndex = layer.open({
                            type: 1,
                            content: singin_tc,
                            anim: false,
                            fixed: true,
                            shade: 0.6,
                            title: 0,
                            scrollbar: true,
                            shadeClose: false,
                            closeBtn: 0,
                            isOutAnim: false,
                            area: ['auto', 'auto'],
                            success:function(layero,index){
                                const lq = $(layero).find('.closed');
                                const ljcz = $(layero).find('.ljcz');
                                var flag = true
                                ljcz.click(function(){
                                    // window.location.href = "/member/wallet"
                                    clearTimeout(timer);
                                    layer.close(LayIndex)
                                    that.$router.push({
                                        path:'/member/wallet'
                                    })
                                })
                                

                                
                                // 定义倒计时函数
                                function countdown() {
                                    // 获取当前倒计时的值
                                    var count = Number(countdownElem.innerText);
                                    // 如果倒计时已经结束，则跳转到目标页面
                                    if (count <= 0) {
                                        // window.location.href = "/member/wallet"
                                        that.$router.push({
                                            path:'/member/wallet'
                                        })
                                        clearTimeout(timer);
                                        layer.close(LayIndex)
                                    } else {
                                        // 否则，更新倒计时元素的值
                                        countdownElem.innerText = count - 1;
                                        // 一秒后再次调用倒计时函数
                                        timer = setTimeout(countdown, 1000);
                                        if(!flag){
                                            clearTimeout(timer);
                                        }
                                        console.log(count)
                                        return timer
                                    }
                                }
                                lq.click(function(){
                                    flag = false
                                    layer.close(LayIndex)
                                    // location.reload()
                                })
                                // 开始倒计时
                                countdown();
                            }
                        });
                    }else{
                        console.log("捕获异常",err)
                        this.$message({
                            message:err.msg,
                            type: 'warning',
                            duration: 1500,
                            onClose:()=>{
                                this.flag = true
                            }
                        })
                    }
                })
            },
            // 弹窗方法
            tcSigin(){
                var that = this
                var sign_tc = $('.sign_tc')
                var LayIndex = layer.open({
                    type: 1,
                    content: sign_tc,
                    anim: false,
                    fixed: true,
                    shade: 0.6,
                    title: 0,
                    scrollbar: true,
                    shadeClose: false,
                    closeBtn: 0,
                    isOutAnim: false,
                    area: [auto, auto],
                    success:function(layero){
                        const lq = $(layero).find('.sign_btn');
                        lq.click(function(){
                            layer.close(LayIndex)
                            location.reload()
                            // that.getUserSigin()
                        })
                    }
                });
            },
            // 询问接口
            existence(type){
                existence({
                    choose:type,
                    user_id:this.userInfo.id
                }).then((res)=>{
                    if(res.code == 200){
                        if(res.data == 'true'){
                            this.askQrcode(); 
                        }
                    }
                }).catch((err)=>{
                    console.log('捕获异常',err.msg)
                    this.$message({
                        message:err.msg,
                        type: 'warning',
                        duration: 1500,
                    })
                })
            },
            // 询问弹窗
            inquiry(){
                this.$confirm('您是否存在86cq传奇会员站账号？', '提示', {
                      confirmButtonText: '是',
                      cancelButtonText: '否',
                      type: 'warning',
                      closeOnClickModal: false,
                      center: true
                  }).then(() => {
                    this.existence(1)
                  }).catch(() => {
                    this.existence(0)
                  });
            },
            // 询问二维码接口
            askQrcode(){
                askQrcode({
                    id:JSON.parse(localStorage.getItem('userInfo')).id,
                    web:localStorage.getItem('img_png')
                }).then(res => {
                    if(res.code == 200){
                        this.code_img_url = res.data.binding_url.qrcode_url
                        this.title = res.data.title
                        this.ask_msg = res.data.msg
                        this.scene_str = res.data.binding_url.scene_str
                        this.$nextTick(() => {
                            this.opneAskQrcode();
                        });
                    }
                }).catch(err => {
                    console.log('捕获异常',err.msg)
                    this.$message({
                        message:err.msg,
                        type: 'warning',
                        duration: 1500,
                    })
                })
            },
            // 获取用户是否合并接口
            getUserMerge(web){
                getUserMerge().then((res)=>{
                    if(res.code == 200){
                        // this.eoMerge = res.data.eoMerge
                        // this.toMerge = res.data.toMerge
                        if(!res.data.toMerge && web == 37){
                            this.merging()
                        }
                        if(!res.data.eoMerge && web == 81){
                            this.askQrcode()
                        }
                    }
                }).catch((err)=>{
                    console.log("捕获异常",err)
                    this.$message({
                        message:err.msg,
                        type: 'warning',
                        duration: 1500,
                    })
                })
            },
           
            // 询问账号密码合并弹窗
            merging(){
                var that = this
                var hb_tc = $('.hb_tc')
                 this.messageBoxInstance = layer.open({
                    type: 1,
                    content: hb_tc,
                    anim: false,
                    fixed: true,
                    shade: 0.6,
                    title: 0,
                    scrollbar: true,
                    shadeClose: false,
                    closeBtn: true,
                    isOutAnim: false,
                    area: [auto, auto],
                    success:function(layero){
                        // const qr = $(layero).find('.queren_btn');
                        // qr.click()
                    }
                });
            },
            // 询问二维码弹窗方法
            opneAskQrcode(){
                var ewm_tc = this.$refs.ewm_tc.innerHTML
                this.messageBoxInstance = this.$confirm(ewm_tc, this.title, {
                    type: false,
                    closeOnClickModal: false,
                    center: true,
                    dangerouslyUseHTMLString: true,
                    showCancelButton: false, // 不显示取消按钮
                    showConfirmButton: false, // 不显示确定按钮
                    callback:(action) =>{
                        clearInterval(this.intervalId)
                    }
                })
                this.intervalId = setInterval(() => {
                    this.checkWxqrBinding();
                }, 1000);
            },
            checkWxqrBinding(){
                checkWxqrBinding({
                    scene_str:this.scene_str
                }).then(res => {
                    if(res.code == 200){
                        clearInterval(this.intervalId);
                        this.getUserInformation()
                        $('.el-message-box__headerbtn').click()
                    }
                }).catch(err => {
                    console.log('捕获异常',err.msg)
                })
            },
            getUserInformation() {
                getUserInformation().then((res) => {
                    if (res.code === 200) {
                        localStorage.setItem('userInfo', JSON.stringify(res.data))
                    } else {
                        console.log(res.msg)
                    }
                }).catch((err) => {
                    //token失效时清除本都数据
                    localStorage.setItem("token", '')
                    console.log('捕获异常', err)
                })
            },
        },
        beforeRouteEnter(to, from, next) {
            // 通过 vm 访问实例，并在进入组件时触发方法
            let userInfo = JSON.parse(localStorage.getItem('userInfo'))
            next(vm => {
                vm.$nextTick(() => {
                    if(localStorage.getItem('w86') == 1 && localStorage.getItem('img_png') == 86){
                        if (userInfo.merge == 0) {
                            // vm.inquiry() 
                            vm.existence(1)
                        }
                    }
                    if (localStorage.getItem('w86') == 1 && localStorage.getItem('img_png') == 37) {
                            vm.getUserMerge(localStorage.getItem('img_png'))
                            console.log('合并',vm.toMerge)
                            // vm.merging()
                    }
                    if (localStorage.getItem('w86') == 1 && localStorage.getItem('img_png') == 81) {
                            vm.getUserMerge(localStorage.getItem('img_png'))
                    }
                });
                
            })
        },
        created(){
            this.getUserSigin()
            this.getArticleData()
        }
    }
</script>

<style scoped>

/* 二维码弹窗 */
.ewm_tc{
    display: none;
    padding: 20px 30px;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
/*  */
.qd{
    height: 100%;
    display: flex;
    flex-direction: column ;
}
.qd_box{
    background: #FFFFFF;
    border-radius: 4px;
    padding: 40px;
}
.qd_top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.qd_title{
    display: flex;
    flex-direction: column;
    align-items: start;
}
.qd_title h2{
    font-size: 24px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #7A6034;
}
.qd_title span{
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #999999;
}
.qd_btn{
    padding: 12px 40px;
    border-radius: 4px;
    text-decoration: none;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
}
.btn_color{
    background: #AC8747;
    transition: all 0.5s;
}
.btn_color:hover{
    filter: brightness(1.2);
    transition: all 0.5s;
}
.qd_content{
    position: relative;
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.card_box{
    position: relative;
    /* width: 150px; */
}
.card_lq{
    bottom: 26px;
}
.card_jl{
    top: 17px;
}
.card_box span{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #D3D3D3;
}
/* 签到进度线 */
.jd_box{
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 28px;
    position: relative;
}
.jindu{
    height: 1px;
    background: #A9805C;
    position: absolute;
    z-index: 2;
}
.xian{
    width: 100%;
    height: 1px;
    background: #e4dfdb;
    position: absolute;
}
.dian_box{
    width: 100%;
    display: flex;
    /* justify-content: space-around; */
    position: absolute;
    z-index: 5;
}
.dian{
    width: 17px;
    height: 17px;
    border: 1px solid #828282;
    transform: rotate(45deg);
    background: #FFFFFF;
    position: relative;
}
.dian_x{
    width: 8px;
    height: 8px;
    background: #828282;
}
.day{
    margin-top: 20px;
    position: relative;
}
.day_pop1{
    left: 5%;
}
.day_pop2{
    left: 15.8%;
}
.day_pop3{
    left: 26.5%;
}
.day_pop4{
    left: 37.5%;
}
.day_pop5{
    left: 48.5%;
}
.day_pop6{
    left: 59%;
}
.day_pop7{
    left: 70%;
}

.day span{
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #846243;
    position: relative;
}
.dian_pop1{
    left: 6%;
}
.dian_pop2{
    left: 19%;
}
.dian_pop3{
    left: 32%;
}
.dian_pop4{
    left: 45%;
}
.dian_pop5{
    left: 58%;
}
.dian_pop6{
    left: 71%;
}
.dian_pop7{
    left:84%;
}

.day_pop1{
    left: 4.5%;
}
.day_pop2{
    left: 15.8%;
}
.day_pop3{
    left: 26.7%;
}
.day_pop4{
    left: 37.6%;
}
.day_pop5{
    left: 48.5%;
}
.day_pop6{
    left: 59%;
}
.day_pop7{
    left: 70%;
}
/* 会员须知 */
.qd_bottom{
    background: #FFFFFF;
    border-radius: 4px;
    padding: 20px 40px;
    margin-top: 10px;
    height: 100%;
}
.qd_bottom span{
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #594D43;
}
.qd_bottom > span > p{
    margin: 0 !important;
}
/* 弹窗 */
.sign_tc{
    display: none;
}
@media (max-width:1600px) {
    /* 签到模块 */
    .card_img{
        width: 140px;
    }
    .qd_box{
        padding: 25px;
    }
    .qd_title h2{
        font-size: 20px;
    }
    .qd_title span{
        font-size: 13px;
    }
    .qd_btn{
        padding: 8px 20px;
        font-size: 14px;
    }
    .qd_content{
        margin-top: 20px;
    }
    .dian_pop1{
    left: 6%;
    }
    .dian_pop2{
        left: 19%;
    }
    .dian_pop3{
        left: 31.5%;
    }
    .dian_pop4{
        left: 44.5%;
    }
    .dian_pop5{
        left: 57.5%;
    }
    .dian_pop6{
        left: 70.5%;
    }
    .dian_pop7{
        left:83%;
    }
    /* .day_pop1{
        left: 4.8%;
    }
    .day_pop2{
        left: 15.5%;
    }
    .day_pop3{
        left: 25.5%;
    }
    .day_pop4{
        left: 36%;
    }
    .day_pop5{
        left: 46.5%;
    }
    .day_pop6{
        left: 57.5%;
    }
    .day_pop7{
        left: 67.5%;
    } */
    /* 会员须知 */
    .qd_bottom{
        padding: 20px 25px;
    }
    .qd_bottom span{
        font-size: 12px;
    }
    
}
@media (max-width:1400px) {
    /* 签到模块 */
    .card_img{
        padding: 0;
    }
    .qd_box{
        padding: 15px;
    }
    .qd_box{
        padding: 25px;
    }
    .qd_title h2{
        font-size: 16px;
    }
    .qd_title span{
        font-size: 12px;
    }
    .qd_btn{
        padding: 5px 20px;
        font-size: 12px;
    }
    .card_img{
        width: 100%;
    }
    .card_box{
        margin-right: 10px;
    }
    .card_box:nth-last-of-type(1){
        margin-right: 0;
    }
    .card_box span{
        font-size: 12px;
    }
    .card_jl{
        top: 13px;
    }
    .card_lq{
        bottom: 20px;
    }
    .dian{
        width: 12px;
        height: 12px;
    }
    .dian_x{
        width: 5px;
        height: 5px;
    }
    .dian_pop1{
        left: 6%;
    }
    .dian_pop2{
        left: 19%;
    }
    .dian_pop3{
        left: 32.5%;
    }
    .dian_pop4{
        left: 45.5%;
    }
    .dian_pop5{
        left: 58.5%;
    }
    .dian_pop6{
        left: 71.5%;
    }
    .dian_pop7{
        left:85%;
    }
    .day{
        margin-top: 10px;
    }
    .day span{
        font-size: 12px;
    }
    /* .day_pop1{
    left: 4.5%;
    }
    .day_pop2{
        left: 14.8%;
    }
    .day_pop3{
        left: 25.6%;
    }
    .day_pop4{
        left: 36%;
    }
    .day_pop5{
        left: 46.5%;
    }
    .day_pop6{
        left: 56.5%;
    }
    .day_pop7{
        left: 67.5%;
    } */

}
@media (max-width:1200px) {
    /* 签到模块 */
    .qd_box{
        padding: 10px;
    }
    .card_box{
        margin-right: 5px;
    }
    .card_jl{
        top: 10px;
    }
    .card_lq{
        bottom: 18px;
    }
    .dian_pop1{
    left: 6%;
    }
    .dian_pop2{
        left: 19%;
    }
    .dian_pop3{
        left: 31.8%;
    }
    .dian_pop4{
        left: 44.8%;
    }
    .dian_pop5{
        left: 57.5%;
    }
    .dian_pop6{
        left: 70.5%;
    }
    .dian_pop7{
        left:83.2%;
    }
    /* .day_pop1{
    left: 4.5%;
}
    .day_pop2{
        left: 14.2%;
    }
    .day_pop3{
        left: 24%;
    }
    .day_pop4{
        left: 34%;
    }
    .day_pop5{
        left: 43.5%;
    }
    .day_pop6{
        left: 53.5%;
    }
    .day_pop7{
        left: 63%;
    } */
    /* 会员须知 */
    .qd_bottom {
        padding: 10px;
    }
}
</style>