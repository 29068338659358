<template>
  <div class="show_box" v-if="this.userInfo.user_growth >= this.material.pc_index_background_kf.jump_url">
    <img class="show_img" v-if="this.material.pc_index_background_kf.img" :src="img_url + this.material.pc_index_background_kf.img">
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    name: 'ShowSuspension',
    data() {
        return {
            
        }
    },
    computed:{
        ...mapState([
        'getClient',
        'material',
        'userInfo'
        ])
    },
    mounted(){
    }
}
</script>

<style scoped>
.show_box{
    position: fixed;
    right: 1%;
    bottom: 5%;
    z-index: 99;
}
.show_img{
    width: 200px;
}
</style>