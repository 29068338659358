<template>
    <div>
      <Header></Header>
      <div class="help">
          <div class="container help_box ">
              <div class="hlep_top">
                  <div class="top_left">
                      <img class="ewm" v-lazy="img_url + material.pc_customer">
                      <div class="ewm_title">扫码添加微信客服</div>
                      <!-- 背景 -->
                      <img class="wx_bg" :src="img_url + material.pc_help.wxkf">
                  </div>
                  <div class="top_right">
                      <div class="top_one">
                          <div class="fjsq">
                              <div class="card_box">
                                  <h4 class="card_text">封禁申诉</h4>
                                  <span class="card_span">
                                      解决被封禁的问题尽快挽救您的损失
                                  </span>
                              </div>
                              <!-- 背景 -->
                              <img class="fjsq_bg" :src="img_url + material.pc_help.fjss">
                          </div>
                          <div class="czwt">
                              <div class="card_box">
                                  <h4 class="card_text">充值问题</h4>
                                  <span class="card_span">
                                      解决您在游过程中的充值问题
                                  </span>
                              </div>
                              <!-- 背景 -->
                              <img class="czwt_bg" :src="img_url + material.pc_help.czwt">
                          </div>
                      </div>
                      <div class="top_two">
                          <div class="wgjb">
                              <div class="card_box">
                                  <h4 class="card_text">外挂举报</h4>
                                  <span class="card_span">
                                      举报外挂,保持良好游戏观环境
                                  </span>
                              </div>
                              <!-- 背景 -->
                              <img class="wgjb_bg" :src="img_url + material.pc_help.wgjb">
                          </div>
                          <div class="zhss">
                              <div class="card_box">
                                  <h4 class="card_text">账号申诉</h4>
                                  <span class="card_span">
                                      关于账号的申诉
                                  </span>
                              </div>
                              <!-- 背景 -->
                              <img class="zhss_bg" :src="img_url + material.pc_help.zhss">
                          </div>
                      </div>
                  </div>
              </div>
              <div class="help_bottom">
                  <div class="bug">
                      <div class="card_box">
                          <h4 class="card_text">BUG建议</h4>
                          <span class="card_span">
                              为了您有更好的游戏体验欢迎您提出宝贵意见
                          </span>
                      </div>
                      <!-- 背景 -->
                      <img class="bug_bg" :src="img_url + material.pc_help.bugjy">
                  </div>
                  <div class="tsqd">
                      <div class="card_box">
                          <h4 class="card_text">投诉渠道</h4>
                          <span class="card_span">
                              快速投诉通道
                          </span>
                      </div>
                      <!-- 背景 -->
                      <img class="tsqd_bg" :src="img_url + material.pc_help.tsqd
">
                  </div>
                  <div class="wtcx">
                      <div class="card_box">
                          <h4 class="card_text">问题查询</h4>
                          <span class="card_span">
                              问题查询快速通道
                          </span>
                      </div>
                      <!-- 背景 -->
                      <img class="wtcx_bg" :src="img_url + material.pc_help.wtcx">
                  </div>
              </div>
          </div>
          <!-- 背景 -->
          <img class="help_bg" :src="img_url + material.pc_help.bj" width="100%">
      </div>
      <Footer></Footer>
    </div>
  </template>
  
  <script>
      import Header from '../components/Header.vue'
      import Footer from '../components/Footer.vue'
      import { mapState } from 'vuex';
      export default {
          components: { Footer,Header },
          name:'Help',
          computed:{
              ...mapState([
                  'getClient',
                  'material'
              ])
          }
  
      }
   
  </script>
  
  <style scoped>
  .help{
      position: relative;
      overflow: hidden;
      top: 0;
      left: 0;
      padding: 320px 0 50px;
      margin-top: 110px;
      background: #000000;
  }
  .help_bg{
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
  }
  .help_box{
      position: relative;
      z-index: 2;
      padding: 0;
      display:flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
  }
  .hlep_top{
      display: flex;
      justify-content: space-between;
  }
  .top_left{
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;
  }
  .wx_bg{
      position: relative;
      top: 0;
      left: 0;
      z-index: -10;
      width: 323px;
  }
  .ewm{
      position: absolute;
      width: 57%;
  }
  .ewm_title{
      position: absolute;
      bottom: 16px;
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #C6C6C6;
      line-height: 30px;
  }
  .top_one{
      display: flex;
      align-items: center;
      justify-content: center;
  }
  .top_two{
      display: flex;
      align-items: center;
      justify-content: center;
  }
  .top_right{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
  }
  .fjsq{
      margin-right: 20px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: end;
  }
  .card_box{
      position: absolute;
      right: 20px;
      width: 40%;
  }
  .fjsq_bg{
      position: relative;
      top: 0;
      left: 0;
      z-index: -10;
      width: 320px;
  }
  .czwt{
      position: relative;
      display: flex;
      align-items: center;
      justify-content: end;
  }
  .czwt_bg{
      position: relative;
      top: 0;
      left: 0;
      z-index: -10;
      width: 320px;
  }
  .card_text{
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #D6D8DF;
      line-height: 30px;
      margin: 0;
  }
  .card_span{
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #9D9FA6;
      line-height: 16px;
  }
  .wgjb{
      margin-right: 20px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: end;
  }
  .wgjb_bg{
      position: relative;
      top: 0;
      left: 0;
      z-index: -10;
      width: 320px;
  }
  .zhss{
      position: relative;
      display: flex;
      align-items: center;
      justify-content: end;
  }
  .zhss_bg{
      position: relative;
      top: 0;
      left: 0;
      z-index: -10;
      width: 320px;
  }
  .help_bottom{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 20px;
  }
  .bug{
      position: relative;
      display: flex;
      align-items: center;
      justify-content: end;
      margin-right: 20px;
  }
  .bug_bg{
      position: relative;
      top: 0;
      left: 0;
      z-index: -10;
      width: 320px;
  }
  .tsqd{
      position: relative;
      display: flex;
      align-items: center;
      justify-content: end;
      margin-right: 20px;
  }
  .tsqd_bg{
      position: relative;
      top: 0;
      left: 0;
      z-index: -10;
      width: 320px;
  }
  .wtcx{
      position: relative;
      display: flex;
      align-items: center;
      justify-content: end;
  }
  .wtcx_bg{
      position: relative;
      top: 0;
      left: 0;
      z-index: -10;
      width: 320px;
  }
  
  @media (max-width: 1300px){
      .help{
          margin-top: 100px;
      }
  }
  @media (min-width: 1700px){
      .container {
          max-width: 1000px !important;
      } 
  }
  
  </style>