import Vue from 'vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap';
// 引入路由
import router from './router';
import VueRouter from 'vue-router'

import store from './store'

// 全局引入二维码生成
import QRCode from 'qrcodejs2';

// 引入图片懒加载插件
import atm from '../public/header/img/ljz.png';
//引入插件
import VueLazyload from 'vue-lazyload' 
// 引入全局login
import Login from './components/Login.vue'
import CNZZ from '@/store/cnzz'

// 引入设备唯一标识
import FingerprintJS from '@fingerprintjs/fingerprintjs'

import VueLuckyCanvas from '@lucky-canvas/vue'

// 引入自动设置根元素字号
import './postcss.config.js'
// import 'amfe-flexible'


Vue.use(VueLuckyCanvas)

// 局部引入elementUI
// import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import elementComponents from '@/components/element.js'
Vue.prototype.img_url= 'https://api.91cq.com/media/public/image?code='
// Vue.prototype.img_url= 'https://api.jndfe.com/media/public/image?code='
// Vue.prototype.img_url= 'http://10.31.222.25/media/public/image?code='
// Vue.prototype.img_url= 'http://bt.zjy.home-nas.cc:18005/media/public/image?code='
// Vue.prototype.img_url= 'http://103.8.221.99:32114/media/public/image?code='
Vue.config.productionTip = false



//注册插件
Vue.use(VueLazyload, { // 添加自定义选项
  preLoad:1.3,
  error: '', // 加载错误时候的图片
  // loading: atm, // 加载中的图片
  attempt: 1,
  listenEvents: [
        'scroll',
        'wheel',
        'mousewheel',
        'resize',
        'animationend',
        'transitionend',
        'touchmove',
    ],
})　

Vue.component('Login',Login)
Vue.use(VueRouter)
Vue.prototype.$qrCode = QRCode;
// 局部引入element ui 的message组件
import {Message,MessageBox } from "element-ui";
Vue.component(Message.name,Message)
Vue.component(MessageBox.name,MessageBox)
Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;
//  cancelToken中的cancel函数
Vue.$httpRequestList=[];
Vue.prototype.$czc = CNZZ //声明cnzz统计代码
Vue.prototype.$finger = FingerprintJS //申明指纹库

// main.js（或公共 JS 文件）
function setRootFontSize() {
  const designWidth = 1920; // 您的设计稿宽度（根据实际情况修改）
  const baseFontSize = 16;  // 基准字体大小（与 rootValue 一致）
  const screenWidth = window.innerWidth;
  
  // 计算动态字体大小
  const fontSize = (screenWidth / designWidth) * baseFontSize;
  document.documentElement.style.fontSize = fontSize + 'px';
}

// 初始化时设置一次
setRootFontSize();

// 监听窗口变化
window.addEventListener('resize', setRootFontSize);


new Vue({
  render: h => h(App),
  store,
  router,
  beforeCreate() {
		Vue.prototype.$bus = this
	}
}).$mount('#app')

