<template>
  <div>
    <Header></Header>
    <div class="tutorial">
        <div class="container_tt">
            <div class="paw_change">
                <div class="bag_title">
                    <h4 class="font_color">新手教程</h4>
                    <div class="tip">
                        <div class="tip_icon flex_box font_color">?</div>
                        <span class="font_color">如遇充值问题，请联系任意平台客服</span>
                    </div>
                </div>
                <div class="content ">
                    <!-- <span v-html="newDate.content">
                    </span> -->
                    <div class="item_box " v-if="material.pc_tutorial_banner ? material.pc_tutorial_banner.img : false">
                        <img src="/tutorial/1.png" width="60%">
                        <video v-if="material.pc_tutorial_banner.type == 1" class="mt-3 tutorial_size" :src="material.pc_tutorial_banner? (img_url + material.pc_tutorial_banner.img) :''" controls width="640" height="360"></video>
                        <img class="mt-3 tutorial_size" v-else :src="material.pc_tutorial_banner.img ? (img_url + material.pc_tutorial_banner.img) :''">
                    </div>
                    <div class="item_box " v-if="material.pc_tutorial_banners ? material.pc_tutorial_banners.img : false">
                        <img src="/tutorial/2.png" width="60%">
                        <video v-if="material.pc_tutorial_banners.type == 1" class="mt-3 tutorial_size" :src="material.pc_tutorial_banners ? (img_url + material.pc_tutorial_banners.img) : ''" controls width="640" height="360"></video>
                        <img class="mt-3 tutorial_size" v-else :src="material.pc_tutorial_banners.img ? (img_url + material.pc_tutorial_banners.img) : ''">
                    </div>
                    <div class="item_box " v-if="material.pc_tutorial_bannerss ? material.pc_tutorial_bannerss.img : false">
                        <img src="/tutorial/3.png" width="60%">
                        <video v-if="material.pc_tutorial_bannerss.type == 1" class="mt-3 tutorial_size" :src="material.pc_tutorial_bannerss ? (img_url + material.pc_tutorial_bannerss.img) : ''" controls width="640" height="360"></video>
                        <img class="mt-3 tutorial_size" v-else :src="material.pc_tutorial_bannerss.img ? (img_url + material.pc_tutorial_bannerss.img) : ''">
                    </div>
                </div>
            </div>
        </div>
        <!-- 背景 -->
        <img class="tutorial_bg" :src="img_url + material.pc_background" width="100%">
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
    import {getArticleData} from '@/api/http.js'
    import Header from '../components/Header.vue';
    import Footer from '../components/Footer.vue';
    import { mapState } from 'vuex';
    export default {
        name:'BeginnerTutorial',
        components:{
            Header,
            Footer
        },
        data() {
            return {
                time:'',
                newDate:{},
                img:'/BeginnerTutorial/img/bg.png',
            }
        },
        computed:{
            ...mapState([
                'getClient',
                'tutorial_id',
                'material'
            ]),
        },
        watch:{
            // tutorial_id(newV,odV){
            //     if(newV){
            //         this.time = parseInt(new Date().getTime() / 1000) + ''
            //         var newTime = localStorage.getItem('tutorialTime')
            //         if(this.time > newTime || !newTime){
            //             this.getArticleData()
            //         }else{
            //             this.newDate = JSON.parse(localStorage.getItem('tutorialData'))
            //         }
            //     }
            //     console.log('tutorial_id',newV);
            // }
        },
        methods:{
            // getArticleData(){
            //     getArticleData({
            //         id:this.tutorial_id
            //     }).then((res)=>{
            //         if(res.code === 200){
            //             localStorage.setItem('tutorialData',JSON.stringify(res.data))
            //             localStorage.setItem('tutorialTime',[res.ex_time])
            //             this.newDate = JSON.parse(localStorage.getItem('tutorialData'))
            //         }
            //     }).catch((err)=>{
            //         console.log('捕获异常',err)
            //     })
            // }
        },
        created(){
            // setTimeout(()=>{
            //     this.time = parseInt(new Date().getTime() / 1000) + ''
            //         var newTime = localStorage.getItem('tutorialTime')
            //         if(this.time > newTime || !newTime){
            //             this.getArticleData()
            //         }else{
            //             this.newDate = JSON.parse(localStorage.getItem('tutorialData'))
            //         }
            // },500)
        }
    }
</script>

<style scoped>
.container_tt{
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-right: auto;
    margin-left: auto;
}
.tutorial{
    position: relative;
    overflow: hidden;
    top: 0;
    left: 0;
    padding: 10px 0;
    margin-top: 110px;
    background: #F4F4F4;
}
.tutorial_bg{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    /* height: 100%; */
}
.paw_change{
    position: relative;
    z-index: 2;
    background: #fff;
    border-radius: 4px;
    height: 100%;
    margin-top: 50px;
}
    .bag_title{
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 30px;
            border-bottom: 1px solid rgb(178, 147, 96,0.2);
     }
    .bag_title h4{
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #AC8747;
        margin: 0;
    }
    .tip{
        display: flex;
        align-items: center;
        justify-content: end;
    }
    .tip_icon{
        width: 13px;
        height: 13px;
        border: 1px solid #806437;
        border-radius: 50%;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        margin-right: 5px;
    }
    .tip span{
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
    }
    .font_color{ 
        color: #886A35;
    }
    /* content */
    .content{
        padding: 30px;
        min-height: 600px;
        
    }
    .item_box{
        display: flex;
       flex-direction: column;
        align-items: center;
        margin-bottom: 50px;
    }
    .tutorial_size{
        max-width: 70%;
        width: 640px;
        height: 360px;
    }
    .content span{
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        margin-top: 20px;
    }
    @media (min-width: 992px){
        .container_tt{
            max-width: 750px;
        }
    }
    @media (min-width: 1200px){
        .container_tt{
            max-width: 875px;
        }
    }
    @media (min-width: 1400px){
        .container_tt{
            max-width: 1030px;
        }
    }
    
    @media (min-width: 1700px){
        .container_tt {
            max-width: 1240px!important;
        }
    }
    @media (max-width:1600px) {
        .bag_title{
            padding: 20px;
        }
        .content{
            padding: 20px;
        }
        .bag_title h4{
            font-size: 17px;
        }
        .tip span{
            font-size: 12px;
        }
        .content span{
            font-size: 12px;
        }
    }
    @media (max-width:1300px) {
        .member{
            margin-top: 100px;
        }
    }
    @media (max-width:1100px) {
        /* top */
        .bag_title h4{
            font-size: 16px;
        }
        .tip span{
            font-size: 12px;
        }
        
    }
</style>