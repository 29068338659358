<template>
  <div class="footer">
    <div class="container footer_box">
        <div class="footer_right">
            <div class="flex_box">
                <img class="footer_logo" :src="img_url + getClient.channel_logo_transparent" height="36">
                <a href="https://beian.miit.gov.cn/" v-if="getClient.icp_status == 1"><p>{{beian}}</p></a>
            </div>
            <span class=" text_ellipsis">抵制不良游戏，拒绝盗版游戏，注意自我保护，谨防上当受骗，适度游戏益脑，沉迷游戏伤身，合理安排时间，享受健康生活。</span>
            <a v-if="material.banhao" :href="material.banhao !== '' ? img_url + material.banhao :'javascript:0'" target="_blank"><p>版号查询</p></a>
        </div>
    </div>
  </div>
</template>

<script>
    import {getClientConfig} from '@/api/http.js'
    import { mapState } from 'vuex'
    export default {
        name:'Footer2',
        data() {
            return {
                beian:'',
                img:{
                    network:'/footer/img/network.png',
                    business:'/footer/img/business.png',
                    gongan:'/footer/img/gongan.png'
                }
            }
        },
        computed:{
            ...mapState([
                'material',
                'getClient',
            ]),
            getClient:{
                get(){
                    return this.$store.state.getClient
                },
                set(val){
                    return this.$store.state.getClient = val
                }
            },
            tutorial_id:{
                get(){
                    return this.$store.state.tutorial_id
                },
                set(val){
                    return this.$store.state.tutorial_id =val
                }
            }
            
        },
        methods:{
            async getConfig() {
                getClientConfig().then(res =>{
                    const a = res.data
                    if(res.code === 200){
                            this.beian = a.icp
                            this.getClient = res.data
                            document.title = a.title
                            this.tutorial_id = res.data.customer.tutorial_id
                        }else{
                            console.log('获取渠道信息',res.msg)
                        }
                })
            }
        },
        created(){
            this.getConfig()
            // this.getClient = clientConfig
            // document.title = clientConfig.title
            // this.tutorial_id = clientConfig.customer.tutorial_id
        }
    }
</script>

<style scoped>
    a{
        text-decoration: none;
    }
    .footer{
        position: relative;
        bottom: 0;
        width: 100%;
        background: #272a2c;
        padding: 30px 0;
    }
    .footer_box{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .footer_left{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: center;
    }
    .icon_box{
        display: flex;
        flex-direction: row;        
        justify-content: space-around;
        align-items: center;
        margin-right: 30px;
    }
    .foot_title{
        display: flex;
        flex-direction: column;
        justify-content: start;
    }
    .icon_box span{
        margin-left: 10px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #050505;
        line-height: 18px;
    }
    .footer_logo{
        margin-right: 20px;
    }
    .footer_right{
        text-align: center;
    }
    .footer_right p{
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #fff;
        margin: 0;
    }
    .footer_right span{
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #fff;
    }
    @media (max-width:1200px) {
        .icon_box span{
            font-size: 12px;
            margin: 0;
        }
        .icon_box img{
            width: 35%;
        }
        .icon_box{
            margin-right: 10px;
        }
        .footer_right p{
            font-size: 14px;
        }
    }
</style>