module.exports = {
    plugins: {
      'postcss-pxtorem': {
        rootValue: 16, // 设计稿宽度/10，例如设计稿宽度为750px，则设置为75
        propList: ['*'], // 需要转换的属性，这里选择全部都进行转换
        selectorBlackList: ['el-', 'ant-'], // 不进行转换的选择器，可以自定义
        replace: true,
        mediaQuery: false,
        minPixelValue: 0,
        exclude: /node_modules/i // 忽略node_modules目录下的文件
      }
    }
  }